import React from 'react'
import Navbar from '../inc/Navbar'
import qr from "../images/draftsmanship payment.jpeg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import AddWorkshop from '../AddWorkshop';
import Workshopfrom from './Workshopform';
function Workshop() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="Darkblue"/>
            <div className='backgroundc'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "white" }} to="/" >HOME</Link></li>
                                <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">WORKSHOP FORM</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-2">
                <div className="container ">
                    <center>
                        <div className='color'>
                        <h1 className='mb-1'><b>TRADITIONAL TERRACOTTA WORKSHOP 2024</b></h1>
                        <p className='mb-2'><b>:Organized by:</b></p>
                            <h1 className='mb-1'><b>DRAFTSMANSHIP ART ACADEMY</b></h1>
                            <p className='mb-0' style={{ color: "maroon" }}><small>UNDER THE INDIAN TRUST ACT 1882 ||  Government Regd. No. IV - 190200103/2021</small></p>

                            <p className='mb-0' style={{ color: "maroon" }}><small>MSME UDYAM REGISTRATION UDYAM-WB-13-060745</small></p>
                            <p className='mb-4' style={{ color: "maroon" }}><small>AN ISO CERTIFIED - 9001:2015 </small></p>

                        </div>
                        <h3 style={{ color: "grey" }}>REGISTRATION FORM</h3>
                    </center>
                </div>
            </div>
            <br />
            <AddWorkshop/>
            <br />

            <center>
                <Workshopfrom/>
            </center>
            {/* <div className="album py-2">
                <div className="container ">
                    <a href={student} target="_blank" rel="noopener noreferrer">Offline Download Form</a>
                </div>
            </div> */}
            
          
            <div className="album py-2">
                <div className="container ">
                    <h4>Payment  QR </h4>
                </div>
            </div>
            <center>
                <div className="album py-0">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-6 mt-1">
                                <div className="card" >
                                    <img src={qr} className="card-img-top" alt="qr" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
            <br />
        </div>
    )
}

export default Workshop