import React, { useState } from "react";
import { Form, Alert, InputGroup } from "react-bootstrap";
import BookDataCareer from "../services/book.workshop";





const AddWorkshop = () => {
    const [title, setTitle] = useState("");
    const [preworkshopdetails, setPreworkshopdetails] = useState("");
    const [presentaddress, setPresentaddress] = useState("");
    const [dateofbirth, setDateofbirth] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [aadharcard, setAadharcard] = useState("");


    const [mobile, setMobile] = useState("");
    const [guardianname, setGuardianname] = useState("");
    const [status, setStatus] = useState("Unpaid");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });


    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const [startDate, setStartDate] = useState(new Date());


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || mobile === "" || guardianname === "" || presentaddress === "" || preworkshopdetails === "" || dateofbirth === "" || gender === "" || email === "" || aadharcard === "" ) {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            mobile,
            guardianname,
            dateofbirth,

            gender,
            email,
          
            aadharcard,
            presentaddress,
            preworkshopdetails,
            status
        }
        console.log(newBook);

        try {
            await BookDataCareer.addBooks(newBook);
            setMassage({ error: false, msg: "Submited Successfuly! " });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setMobile("");
        setGuardianname("");
        setDateofbirth("");
        setGender("");
        setSelectedDate("")
        setEmail("");
        setAadharcard("");
       

        setPresentaddress("");
        setPreworkshopdetails("");
    };



    return (
        <>






            {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMassage("")}
            >
                {""}
                {massage?.msg}
            </Alert>
            )}

            <Form onSubmit={handleSubmit} >
                <div className="album py-2">
                    <div className="container">
                        <div className="row ">

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookTitle">
                                    <InputGroup>
                                        <Form.Control type="text"
                                            placeholder="Applicant Name"

                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>


                            </div>


                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookGuardianname">
                                    <Form.Control type="text"
                                        placeholder="Guardian's Name"
                                        value={guardianname}
                                        onChange={(e) => setGuardianname(e.target.value)}
                                    />

                                </Form.Group>

                            </div>
                            <div className="col-md-6">

                                <Form.Group className="mb-3" controlId="formBookPresentaddress">

                                    <Form.Control type="text"
                                        placeholder="Present Full Address"
                                        value={presentaddress}
                                        onChange={(e) => setPresentaddress(e.target.value)}
                                    />

                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookDateofbirth">
                                    <Form.Control type="text"
                                        placeholder="Date of Birth "
                                        value={dateofbirth}
                                        onChange={(e) => setDateofbirth(e.target.value)}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookGender">
                                    <Form.Control type="text"
                                        placeholder="Gender- Male/Female/Other"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookEmail">
                                    <Form.Control type="text"
                                        placeholder="E-mail Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMobile">

                                    <Form.Control type="text"
                                        placeholder="Mobile"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookAadharcard">

                                    <Form.Control type="text"
                                        placeholder="Aadhar Card No."
                                        value={aadharcard}
                                        onChange={(e) => setAadharcard(e.target.value)}
                                    />
                                </Form.Group>
                            </div>


                            {/* <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookMothertongue">

                                    <Form.Control type="text"
                                        placeholder="Mother Tongue"
                                        value={mothertongue}
                                        onChange={(e) => setMothertongue(e.target.value)}
                                    />
                                </Form.Group>
                            </div> */}
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBookPreworkshopdetails">


                                    <Form.Control as="textarea" row={3}
                                        placeholder="Previous Workshop Details"
                                        value={preworkshopdetails}
                                        onChange={(e) => setPreworkshopdetails(e.target.value)}
                                    />

                                </Form.Group>

                            </div>



                            {/* <Form.Select className="mb-3" controlId="formBookJobcategary">
                
              

        
      
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>One</option>
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>Two</option>
      <option value={ jobcategary} onChange={(e) => setJobcategary(e.target.value)}>Three </option>
      
      
      
     
     
     
    
        </Form.Select> */}


                            {/* <Form.Group className="mb-3" controlId="formBookJobcategary">
          <Form.Label>Disabled select menu</Form.Label>
          <Form.Select >
            <option>Animation</option>
            <option>Game 2D </option>
            <option>VFX</option>
          </Form.Select>
             </Form.Group> */}

                            {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}








                        </div>
                        <center><h6><b><u>DECLARATION</u></b></h6></center>

                        <input type="checkbox" id="myCheck" name="test" required /> No accommodation is provider by us: We can just suggest your accommodation according to you wish. You have to pay for that. (We will not take any payment) I agree.
                        <br />
                        <br />


                        <button className="newbuton" type="Submit">

                            Submit

                        </button>
                    </div>
                </div>

            </Form>






        </>


    )
}
export default AddWorkshop;